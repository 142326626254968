.HomeSlideShow {
  position: relative;
  text-align: center;

  .slick-dots {
    bottom: 25px;

    button {
      width: 10px;
      height: 10px;
      background-color: #8f8f8f;
      border-radius: 50%;

      &::before {
        content: '';
      }
    }

    li {
      margin: 0;
    }
  }

  .slick-active {
    button {
      background-color: #fff;
    }
  }

  .slick-slide {
    display: flex;

    img {
      display: inline-block;
    }
  }

  @media (--md) {
    overflow: hidden;
    .slick-slide {
      display: block;
    }

    .slick-dots {
      position: static;
      bottom: 0;
    }

    .slick-active {
      button {
        border: solid 1px #8f8f8f;
      }
    }

    &.slick-dotted.slick-slider {
      margin-bottom: 0;
    }
  }
}

.HomeSlideShow__item {
  position: relative;
  justify-content: center;
  align-items: center;
  height: 890px;
  text-align: center;
  background-size: cover;

  &:nth-child(1) {
    background-image: url('../../images/home/slider/bg-01@2x.jpg');
  }

  &:nth-child(2) {
    background-image: url('../../images/home/slider/bg-02@2x.jpg');
  }

  &:nth-child(3) {
    background-image: url('../../images/home/slider/bg-03@2x.jpg');
  }

  &:nth-child(4) {
    background-image: url('../../images/home/slider/bg-04@2x.jpg');
  }

  &:nth-child(5) {
    background-image: url('../../images/home/slider/bg-05@2x.jpg');
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    content: '';
  }

  @media (--md) {
    position: static;
    width: auto;
    height: initial;

    &::before {
      display: none;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      background-image: none;
    }
  }
}

.HomeSlideShow__itemLink {
  display: block;
  color: inherit;
  text-decoration: none;
}
