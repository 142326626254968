.WorksArchive {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: #f5f5f5;
  border-top: solid 1px #f2f1f0;

  @media (--md) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.WorksArchive__categories {
  display: flex;
  flex-wrap: wrap;
  margin: 50px -8px -8px -8px;
  font-size: 1.1428rem;
  line-height: 1;
  text-align: center;

  .Button {
    flex-basis: 176px;
    box-sizing: border-box;
    margin: 8px;
    overflow: hidden;
  }

  .Button__link {
    padding: 15px;
  }

  @media (--md) {
    margin: 25px 0 0 0;
    padding-right: 25px;
    padding-left: 25px;

    .Button {
      flex-basis: calc(50% - 8px);
      margin: 4px;
    }
  }
}

.WorksArchive__list {
  margin-top: 50px;

  @media (--md) {
    margin-top: 25px;
    padding-right: 25px;
    padding-left: 25px;
  }
}

/* アーカイブページのときにallをカレント処理 */
.post-type-archive-works .Button__link[href$='works/'] {
  color: #000;
  background-color: #fff;
}
