.ContactBlock {
  padding-top: 64px;

  @media (--md) {
    padding-top: 0;
  }
}

.ContactBlock__body {
  position: relative;
  padding-top: 65px;
  padding-bottom: 65px;
  text-align: center;
  background-color: #000;

  &::after {
    position: absolute;
    right: -4px;
    bottom: 0;
    color: #fff;
    font-weight: bold;
    font-size: 2.85rem;
    letter-spacing: 3px;
    content: 'CONTACT';
  }

  @media (--md) {
    padding: 25px 25px 40px 25px;

    &::after {
      right: 0;
      font-size: 1.25rem;
    }
  }
}

.ContactBlock__text {
  color: #fff;
  font-size: 1.14rem;

  @media (--md) {
    font-size: 0.9285rem;
    text-align: left;
  }
}

.ContactBlock__button {
  margin-top: 20px;
}
