.ServicesModelCase {
  padding-top: 55px;
  padding-bottom: 55px;
  background-image: url('../../images/services/bg-01@2x.jpg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;

  @media (--md) {
    padding: 25px;
  }
}

.ServicesModelCase__body {
  padding: 55px 85px;
  font-size: 0.9285rem;
  background-color: rgba(255, 255, 255, 0.95);

  @media (--md) {
    padding: 25px;
  }
}

.ServicesModelCase__title {
  text-align: center;
}

.ServicesModelCase__price {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;

  @media (--md) {
    display: block;
    margin-top: 0;
  }
}

.SevicesModelCase__priceCol {
  flex-basis: 380px;
  margin-top: -20px;

  @media (--md) {
    margin-top: 0;
  }
}

.ServicesModelCase__priceTable {
  width: 100%;
  margin-top: 20px;

  thead {
    border-bottom: solid 1px #dadada;
  }

  tr {
    border-bottom: solid 1px #dadada;
  }

  th {
    text-align: left;
  }

  th,
  td {
    padding: 5px;
  }

  tbody {
    th {
      width: 40%;
      padding-left: 2em;
    }
  }
}

.ServicesModelCase__priceNote {
  margin-top: 10px;
  text-align: center;
}

.ServicesModelCase__text {
  margin-top: 30px;
  text-align: center;

  @media (--md) {
    margin-top: 20px;
    text-align: left;

    br {
      display: none;
    }
  }
}

.ServicesModelCase__button {
  margin-top: 20px;
  text-align: center;
}
