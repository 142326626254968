.HumbargerMenu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: var(--zindexFoward);
  display: none;
  width: 25px;
  height: 25px;

  @media (--md) {
    display: block;
  }
}

.HumbargerMenu__bar {
  width: 100%;
  border-top: solid 2px #000;

  + .HumbargerMenu__bar {
    margin-top: 5px;
  }
}


html.remodal-is-locked {
  overflow: hidden;
  touch-action: none;
}

#overlay{
	z-index: 3;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	display: none;
	opacity: .85;
  @media (--md) {
  }
}


.icn-humberger {
  display: none;
	position: fixed;
	right: 0;
	top: 10px;
  width: 70px;
  height: 60px;
  cursor: pointer;
  z-index: 21;
  border-radius: 1px;
	background-color: rgba(255,255,255,0.50);
	@media (--md) {
		right: 0;
		top: 0;
		display: block;
	}
	span{
	  width: 50px;
	  height: 5px;
	  display: block;
	  background-repeat: no-repeat;
	  background-size: cover;
	  position: absolute;
    left: 41px;
    top: 29px;
	  margin-left: -25px;
	  background-color: black;
		&.bar-top{
		}
		&.bar-middle{
		}
		&.bar-bottom{
		}
	}
}





.icn-humberger {
  span {
    transition: all 0.3s;
    transform: rotate(0deg);
  }
  .bar-top {
    transform: translateY(-20px);
  }
  .bar-bottom {
    transform: translateY(20px);
  }
}

.icn-humberger:hover {
  .bar-top {
    transform: translateY(-20px);
  }
  .bar-bottom {
    transform: translateY(20px);
  }
}

.icn-humberger.is-open {
	background-color: transparent;
  .bar-middle {
    background: rgba(255, 255, 255, 0);
  }
  .bar-top {
    transform: rotate(-45deg) translateY(0px);
    background-color: white;
  }
  .bar-bottom {
    transform: rotate(45deg) translateY(0px);
    background-color: white;
  }
}

@media (--md) {

	
	.icn-humberger span {
		width: 40px;
		height: 3px;
	}
	
	.icn-humberger {
	  span {
	    transition: all 0.3s;
	    transform: rotate(0deg);
	  }
	  .bar-top {
	    transform: translateY(-10px);
	  }
	  .bar-bottom {
	    transform: translateY(10px);
	  }
	}
	
	.icn-humberger:hover {
	  .bar-top {
	    transform: translateY(-10px);
	  }
	  .bar-bottom {
	    transform: translateY(10px);
	  }
	}
	
	.icn-humberger.is-open {
	  .bar-middle {
	    background: rgba(255, 255, 255, 0);
	  }
	  .bar-top {
	    transform: rotate(-45deg) translateY(0px);
	  }
	  .bar-bottom {
	    transform: rotate(45deg) translateY(0px);
	  }
	}
}


.nav{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	width: 80%;
  display: none;
	ul{
		li{
			text-align: center;
			border-bottom: gray solid 1px;
			a{
				color: white;
				display: block;
			  font-size: 1.1428rem;
			  line-height: 4;
			  text-decoration: none;
				&:hover{
				}
			}
		}
	}
}

