.FooterNav {
  display: flex;
  margin-top: -20px;
  font-size: 0.9285rem;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (--md) {
    justify-content: center;
  }

  @media (max-width: 680px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.FooterNav__item {
  margin-top: 20px;
  font-weight: bold;

  + .FooterNav__item {
    margin-left: 45px;
  }

  @media (--sm) {
    &:first-child{
      margin-left: 0;
      width: 100%;
    }
  	width: 50%;
  	margin-left: 0;
	  + .FooterNav__item {
	    margin-left: 0;
	  }

  }
}

.FooterNav__itemLink {
}

.FooterNavSub {
  margin-top: 10px;
  font-weight: normal;
  text-transform: uppercase;
}

.FooterNavSub__item {
	line-height: 2.5;
  &::before {
    content: '-';
  }
}
