.HomeNews {
  padding-top: 65px;
  padding-bottom: 65px;

  @media (--md) {
    padding: 25px;
  }
}

.HomeNews__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1190px;
  margin-right: auto;
  margin-left: auto;

  @media (--md) {
    display: block;
  }
}

.HomeNews__title {
  position: relative;
  padding-top: 30px;
  font-size: 1.64rem;

  &::before,
  &::after {
    position: absolute;
    left: 0;
    content: '';
  }

  &::before {
    top: 1px;
    width: 120px;
    border-top: solid 3px #000;
  }

  &::after {
    top: 0;
    width: 240px;
    border-top: solid 1px #ccc;
  }

  @media (--md) {
    padding-top: 15px;
    font-size: 1.1428rem;
    text-align: center;

    &::before,
    &::after {
      left: 50%;
      transform: translateX(-50%);
    }

    &::before {
      width: 60px;
      transform: translateX(-60px);
    }

    &::after {
      width: 120px;
    }
  }
}

.HomeNews__list {
  @media (--md) {
    margin-top: -10px;
  }
}

.HomeNews__listItem {
  font-size: 0.92rem;
  line-height: 2.5;

  @media (--md) {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.8;
    border-bottom: solid 1px #ccc;
  }
}

.HomeNews__listItemDate {
  padding-right: 20px;

  @media (--md) {
    display: block;
    padding-right: 0;
  }
}

.HomeNews__listItemLink {
  color: inherit;

  &:hover {
    text-decoration: none;
  }

  @media (--md) {
    text-decoration: none;
  }
}

.HomeNews__button {
  @media (--md) {
    margin-top: 15px;
    text-align: center;
  }
}
