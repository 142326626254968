.ContactForm {
  margin-top: 40px;
  padding: 80px;
  background-color: #f2f2f2;

  textarea,
  input[type='text'],
  input[type='email'],
  input[type='tel'] {
    width: 100%;
    padding: 1rem;
    border: none;
  }

  @media (--md) {
    margin-top: 25px;
    padding: 25px;
  }
}

.ContactForm__row {
  display: flex;
  align-items: center;

  + .ContactForm__row {
    margin-top: 30px;
  }

  @media (--md) {
    display: block;

    + .ContactForm__row {
      margin-top: 15px;
    }
  }
}

.ContactForm__label {
  flex-basis: 25%;

  @media (--md) {
    br {
      display: none;
    }
  }
}

.ContactForm__input {
  flex-basis: 75%;

  @media (--md) {
    margin-top: 10px;
  }
}

.ContactForm__checkBox {
  label {
    position: relative;
    padding-left: 40px;
  }

  input[type='checkbox'] {
    display: none;

    + span::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      width: 25px;
      height: 25px;
      background-color: #fff;
      border: solid 1px #eee;
      transform: translateY(-50%);
      content: '';
    }

    &:checked + span::after {
      position: absolute;
      top: 50%;
      left: 8px;
      z-index: var(--zindexFoward);
      width: 10px;
      height: 7px;
      margin-top: -6px;
      border-bottom: solid 1px green;
      border-left: solid 1px green;
      transform: rotate(-45deg);
      content: '';
    }
  }

  + .ContactForm__checkBox {
    margin-top: 20px;
  }

  .wpcf7-list-item {
    display: block;
    + .wpcf7-list-item {
      margin-top: 20px;
    }
  }
}

.ContactForm__button {
  margin-top: 30px;
  text-align: center;

  @media (--md) {
    margin-top: 15px;
  }
}
