.GlobalFooter {
  margin-top: 100px;
  padding-bottom: 40px;

  @media (--md) {
    margin-top: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
  }
}

.GlobalFooter__wrap {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;

  @media (--md) {
    display: block;
  }
}

.GlobalFooter__copyright {
  margin-top: 40px;
  font-size: 0.9285rem;
  text-align: center;

  @media (--md) {
    margin-top: 25px;
    font-size: 0.8571rem;
  }
}
