html {
  font-size: var(--fontSizeBase);
  font-family: var(--fontFamilyGothic);
}

@media all and (-ms-high-contrast: none) {
  html {
    font-family: Verdana, Meiryo, sans-serif;
  }
}
@media all and (-ms-high-contrast: active) {
  html {
    font-family: Verdana, Meiryo, sans-serif;
  }
}

body {
  color: var(--colorFontBase);
  line-height: 1.6;
  overflow-wrap: break-word;
}

ul {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

input[type='tel'],
input[type='text'],
input[type='email'],
textarea {
  box-sizing: border-box;
  font-size: 1rem;
}

mark {
  font-weight: normal;
  font-style: normal;
  background-color: #fcff9c;

  &:hover {
    background-color: #f1f34f;
  }
}
