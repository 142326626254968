.ContactNote {
  margin-top: 30px;
  text-align: center;

  a {
    color: currentColor;

    &:hover {
      text-decoration: none;
    }
  }

  @media (--md) {
    margin-top: 15px;
    font-size: 0.9285rem;
  }
}
