.Container {
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
}

.Container.-ss {
  max-width: var(--widthContainerSS);
}

.Container.-s {
  max-width: var(--widthContainerS);
}

.Container.-m {
  max-width: var(--widthContainerM);
}

.Container.-l {
  max-width: var(--widthContainerL);
}
