.FooterCompanyData {
  font-size: 0.9285rem;

  @media (--md) {
    margin-top: 25px;
    padding-top: 25px;
    text-align: center;
    border-top: solid 1px #ccc;
  }
}

.FooterCompanyData__logo {
  @media (--md) {
    svg {
      width: 180px;
    }
  }
}

.FooterCompanyData__sns {
  margin-top: 10px;

  a {
    text-decoration: none;

    + a {
      margin-left: 10px;
    }
  }
}

.FooterCompanyData__name {
  margin-top: 25px;
  letter-spacing: 1px;
}

.FooterCompanyData__item {
  margin-top: 15px;

  dt {
    font-weight: bold;
  }

  a {
    color: inherit;
  }
}
