.PrevNext {
  display: flex;
  justify-content: center;
  margin-top: 60px;

  @media (--md) {
    margin-top: 0;
    padding-bottom: 25px;
  }
}

.PrevNext__next,
.PrevNext__prev {
  > a {
    position: relative;
    display: block;
    padding: 20px;
    transition: background-color 0.5s ease-out;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      background-color: inherit;
      border-bottom: solid 1px black;
      content: '';
    }
  }
}

.PrevNext__next {
  > a::before {
    margin-left: -10px;
    border-right: solid 1px black;
    transform: rotate(135deg);
  }
}

.PrevNext__prev {
  > a::before {
    margin-left: -10px;
    border-left: solid 1px black;
    transform: rotate(-135deg);
  }
}
