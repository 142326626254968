.AboutMessage {
  display: flex;
  justify-content: space-between;
  margin-top: 82px;

  @media (--md) {
    display: block;
    margin-top: 0;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.AboutMessage__image {
  flex-basis: 450px;

  @media (--md) {
    margin-top: 25px;
    text-align: center;
  }
}

.AboutMessage__body {
  flex-basis: 500px;
}

.AboutMessage__title {
  text-align: left;

  @media (--md) {
    margin-top: 15px;
    text-align: center;
  }
}

.AboutMessage__text {
  margin-top: 35px;
  font-family: serif;
  line-height: 2.1428;
	text-align: justify;

  @media (--md) {
    margin-top: 15px;
    font-size: 0.9825rem;
    line-height: 1.8;

    br {
      display: none;
    }
  }
}

.AboutMessage__ceo {
  max-width: 230px;
  margin-top: 25px;
  margin-left: auto;
  font-size: 0.9285rem;
  text-align: center;

  @media (--md) {
    max-width: 250px;
    margin-right: auto;
  }
}

.AboutMessage__ceoText {
  display: block;

  @media (--md) {
    font-size: 0.9825rem;
  }
}

.AboutMessage__ceoName {
  width: 150px;
  margin-top: 10px;
}
