.AboutRecruit {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #f5f5f5;

  @media (--md) {
    padding: 25px;
  }
}

.AboutRecruit__title {
  text-align: center;
}

.AboutRecruit__wrap {
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: #fff;

  @media (--md) {
    padding: 25px;
  }
}

.AboutRecruit__text {
  margin-top: 35px;
  font-size: 0.9285rem;
  line-height: 2;
  text-align: center;

  @media (--md) {
    margin-top: 20px;
    line-height: 1.8;
    text-align: left;

    br {
      display: none;
    }
  }
}

.AboutRecruit__data {
  max-width: 570px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;

  @media (--md) {
    margin-top: 15px;
  }
}

.AboutRecruit__dataItem {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 0.9285rem;
  border-bottom: solid 1px #cecece;

  > dt {
    float: left;
    width: 7em;
    font-weight: bold;
  }

  > dd {
    overflow: hidden;
  }

  @media (--md) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.AboutRecruit__button {
  margin-top: 60px;
  text-align: center;

  @media (--md) {
    margin-top: 15px;
  }
}
