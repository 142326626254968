.HomePickUpContents {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 455px;
  color: #fff;
  background-image: url('../../images/home/bg-02@2x.jpg');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    content: '';
  }

  @media (--md) {
    width: auto;
    height: auto;
    padding: 25px;
  }

  &.-services {
    background-image: url('../../images/home/bg-01@2x.jpg');
  }

  &.-aboutUs {
    background-image: url('../../images/home/bg-02@2x.jpg');
  }
}

.HomePickUpContents__body {
  z-index: var(--zindexFoward);
  max-width: 265px;

  @media (--md) {
    max-width: initial;
  }
}

.HomePickUpContents__title {
  font-size: 2.85rem;
  letter-spacing: 2px;
  text-align: center;

  @media (--md) {
    font-size: 1.1428rem;
  }
}

.HomePickUpContents__subTitle {
  margin-top: 20px;
  padding-bottom: 10px;
  font-weight: normal;
  font-size: 1.71rem;
  text-align: center;
  border-bottom: solid 1px #fff;

  @media (--md) {
    margin-top: 10px;
    padding-bottom: 5px;
    font-size: 1rem;
  }
}

.HomePickUpContents__text {
  margin-top: 25px;
  line-height: 1.8;

  @media (--md) {
    margin-top: 12px;
    font-size: 0.9285rem;
  }
}

.HomePickUpContents__button {
  margin-top: 25px;
  text-align: center;
}
