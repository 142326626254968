.ServicesProjects {
  padding-top: 80px;

  @media (--md) {
    padding-top: 25px;
  }
}

.ServicesProjects__title {
  text-align: center;
}

.ServicesProjects__wrap {
  margin-top: 30px;

  @media (--md) {
    margin-top: 0;
  }
}

.ServicesProjects__item {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;

  + .ServicesProjects__item {
    border-top: solid 1px #ddd;
  }

  @media (--md) {
    display: block;
    margin-right: 25px;
    margin-left: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.ServicesProjects__itemImage {
  flex-basis: 420px;
  text-align: center;
}

.ServicesProjects__itemBody {
  flex-basis: 545px;

  @media (--md) {
    margin-top: 10px;
  }
}

.ServicesProjects__itemNum {
  margin-right: 20px;
  font-size: 2.8571rem;
  border-bottom: solid 3px #000;

  @media (--md) {
    flex-basis: 2rem;
    font-size: 1.4285rem;
    text-align: center;
  }
}

.ServicesProjects__itemTitle {
  display: flex;
  align-items: center;
}

.ServicesProjects__itemName {
  margin-top: 10px;
  font-size: 1.4285rem;

  @media (--md) {
    font-size: 1.1428rem;
  }
}

.ServicesProjects__itemClient {
  font-size: 1.1428rem;

  @media (--md) {
    font-size: 1rem;
  }
}

.ServicesProjects__itemText {
  margin-top: 30px;
  font-size: 0.9285rem;
	text-align: justify;

  @media (--md) {
    margin-top: 15px;
  }
}

.ServicesProjects__itemData {
  margin-top: 30px;
  font-size: 0.9285rem;

  dl {
    overflow: hidden;
    + dl {
      margin-top: 5px;
    }
  }

  dt {
    float: left;
    font-weight: bold;
  }

  a {
    color: currentColor;

    &:hover {
      text-decoration: none;
    }
  }

  @media (--md) {
    margin-top: 15px;
  }
}
