.NewsCategories {
  @media (--md) {
    padding-top: 25px;
    padding-right: 25px;
    padding-left: 25px;
  }

  @media (--sm) {
    padding-bottom: 25px;
    background-color: #f2f2f2;
  }
}

.NewsCategories__label {
  padding: 10px;
  color: #fff;
  font-size: 1.2857rem;
  text-align: center;
  background-color: #000;
}

.NewsCategories__list {
  padding-top: 20px;

  @media (--md) {
    padding-top: 0;
    background-color: #fff;
  }
}

.NewsCategories__listItem {
  + .NewsCategories__listItem {
    margin-top: 10px;
  }

  @media (--md) {
    + .NewsCategories__listItem {
      margin-top: 0;
      border-top: solid 1px #cdcdcd;
    }
  }
}

.NewsCategories__listItemLink {
  color: currentColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (--md) {
    display: block;
    padding: 10px;
  }
}

/* カテゴリーリンクのカレント処理 */
.category-blog .NewsCategories__listItemLink[href$='blog/'],
.category-infomation .NewsCategories__listItemLink[href$='infomation/'],
.category-event-workshop .NewsCategories__listItemLink[href$='workshop/'],
.category-daily .NewsCategories__listItemLink[href$='daily/'] {
  text-decoration: underline;
}
