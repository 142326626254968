.ContactMessage {
  margin-top: 65px;
  line-height: 2;
  text-align: center;

  @media (--md) {
    margin-top: 0;
    padding: 25px;
    font-size: 0.9285rem;
    text-align: left;

    br {
      display: none;
    }
  }
}
