.NewsWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  @media (--md) {
    display: block;
    margin-top: 0;
  }
}
