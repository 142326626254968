.GlobalNavSub {
  position: absolute;
  top: calc(100% + 25px);
  display: none;
  width: 10rem;
  font-size: 0.85rem;
  text-transform: uppercase;
}

.GlobalNavSub__item {
  &::before {
    content: '-';
  }
}

.GlobalNavSub__itemLink {
  color: inherit;
  text-decoration: none;
}
