.AboutPhilosophy {
  margin-top: 85px;

  @media (--md) {
    margin-top: 20px;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.AboutPhilosophy__title {
  text-align: center;
}

.AboutPhilosophy__subTitle {
  margin-top: 47px;
  font-size: 2rem;
  font-family: serif;
  letter-spacing: 2.8px;
  text-align: center;

  @media (--md) {
    margin-top: 20px;
    font-size: 1.2857rem;
  }
}

.AboutPhilosophy__text {
  margin-top: 10px;
  font-family: serif;
  line-height: 2.7142;
  text-align: center;

  @media (--md) {
    font-size: 0.9825rem;
    line-height: 1.8;
    text-align: left;
		text-align: justify;
    br {
      display: none;
    }
  }
}
