.HomePickUpContentsWrap {
  display: flex;

  @media (--md) {
    display: block;
    max-width: var(--widthContainerS);
    margin-right: auto;
    margin-left: auto;
  }
}
