.NewsItem {
  + .NewsItem {
    margin-top: 60px;
  }

  @media (--md) {
    padding-right: 25px;
    padding-left: 25px;

    + .NewsItem {
      margin-top: 25px;
    }
  }
}

.NewsItem__title {
  position: relative;
  padding-bottom: 10px;
  font-size: 1.2857rem;
  border-bottom: solid 2px #cdcdcd;

  &::after {
    position: absolute;
    right: 0;
    bottom: -5px;
    content: '';
    width: 120px;
    border-top: solid 3px currentColor;
  }

  @media (--md) {
    font-size: 1.1428rem;
  }
}

.NewsItem__link {
  color: currentColor;
  text-decoration: none;
}

.NewsItem__meta {
  display: flex;
  margin-top: 15px;
  font-weight: bold;

  @media (--md) {
    margin-top: 10px;
    font-size: 0.9285rem;
  }
}

.NewsItem_category {
  &::after {
    content: ':';
    margin-right: 10px;
  }
}

.NewsItem__content,
.NewsItem__summary {
  margin-top: 30px;

  @media (--md) {
    margin-top: 15px;
    font-size: 0.9285rem;
  }
}

.NewsItem__content {
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  dl,
  div,
  p {
    margin-top: 15px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.NewsItem__image {
  margin-top: 20px;

  img {
    width: 100%;
  }
}

.NewsItem__more {
  margin-top: 20px;
  text-align: center;
}
