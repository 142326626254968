.TitleUnderBar {
  font-size: 1.6428rem;
  letter-spacing: 1px;

  @media (--md) {
    font-size: 1.1428rem;
  }
}

.TitleUnderBar__text {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    border-bottom: solid 5px #000;
    transform: translateX(-50%);
    content: '';
  }

  @media (--md) {
    &::after {
      border-bottom-width: 3px;
    }
  }
}
