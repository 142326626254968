.GlobalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 36px;

  @media (--md) {
    display: block;
    padding: 20px;
  }
}

.GlobalHeader.-home {
  position: absolute;
  left: 50%;
  z-index: var(--zindexFoward);
  width: var(--widthContainerM);
  transform: translateX(-50%);

  .GlobalNav__itemLink {
    color: #fff;
  }

  @media (--md) {
  	position: relative;
    top: 0;
    left: 0;
    width: auto;
    transform: initial;
  }
}
