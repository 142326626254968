.PageVisual {
  position: relative;
}

.PageVisual__wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  @media (--md) {
    box-sizing: border-box;
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.PageVisual__title {
  font-size: 2.8571rem;
  letter-spacing: 4.2px;

  &.-white {
    color: #fff;
  }

  @media (--md) {
    font-size: 1.2857rem;
  }
}

.PageVisual__text {
  margin-top: 10px;

  &.-white {
    color: #fff;
  }

  @media (--md) {
    font-size: 0.9285rem;
    text-align: center;
  }

  @media (--sm) {
    text-align: left;
  }
}
