.Pagination {
  margin-top: 50px;
  font-size: 0.9375rem;
  font-family: var(--fontFamilyGothic);
  line-height: 1;
  letter-spacing: 1.5;
  text-align: center;

  a,
  span {
    position: relative;
    margin-right: 5px;
    margin-left: 5px;
    padding: 10px 15px;
    color: currentColor;
    text-decoration: none;
    background-color: #fff;
    border: solid 1px #000;
    transition: background-color 0.5s ease-out;

    &:hover {
      color: #fff;
      background-color: #000;
    }
  }

  .current {
    color: #fff;
    font-weight: normal;
    background-color: #000;
  }

  .nextpostslink,
  .previouspostslink {
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      margin-top: -5px;
      background-color: inherit;
      border-bottom: solid 1px #000;
      content: '';
    }

    &:hover {
      &::before {
        border-color: #fff;
      }
    }
  }

  .nextpostslink {
    &::before {
      margin-left: -7px;
      border-right: solid 1px #000;
      transform: rotate(-45deg);
    }
  }

  .previouspostslink {
    &::before {
      margin-left: -3px;
      border-left: solid 1px #000;
      transform: rotate(45deg);
    }
  }

  @media (--md) {
    margin-top: 10px;
    padding-bottom: 35px;

    a,
    span {
      padding: 5px 10px;
    }
  }
}
