.WorksSingleContent {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  @media (--md) {
    display: block;
    max-width: 610px;
    margin-top: 25px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
  }
}

.WorksSingleContent__main {
  flex-shrink: 0;
  order: 1;
  max-width: 357px;

  @media (--md) {
    max-width: initial;
  }
}

.WorksSingleContent__images {
  flex-shrink: 0;
  order: 2;
  max-width: 607px;
  min-height: 375px;

  .slick-dots {
    position: static;
    margin-top: 10px;

    button {
      width: 10px;
      height: 10px;
      background-color: #ccc;
      border-radius: 50%;

      &::before {
        content: '';
      }
    }

    li {
      margin: 0;
    }
  }

  .slick-active {
    button {
      background-color: #000;
    }
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  @media (--md) {
    min-height: initial;
  }

  @media (--sm) {
    margin-right: -25px;
    margin-left: -25px;
  }
}

.WorksSingleContent__title {
  font-size: 1.7142rem;

  @media (--md) {
    margin-top: 25px;
    font-size: 1.2857rem;
  }
}

.WorksSingleContent__meta {
  margin-top: 15px;

  dl {
    display: flex;
    font-size: 1.4285rem;
    line-height: 1.3;
  }

  dt {
    margin-right: 10px;

    &::after {
      content: ': ';
    }
  }

  @media (--md) {
    margin-top: 10px;

    dl {
      font-size: 1.1428rem;
    }
  }
}

.WorksSingleContent__description {
  margin-top: 15px;

  @media (--md) {
    font-size: 0.9285rem;
  }
}

.WorksSingleContent__url {
  margin-top: 15px;

  > a {
    color: currentColor;

    &:hover {
      text-decoration: none;
    }
  }

  @media (--md) {
    font-size: 0.9285rem;
  }
}
