.AboutCompanyProfile {
  margin-top: 80px;

  @media (--md) {
    margin-top: 25px;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.AboutCompanyProfile__title {
  text-align: center;
}

.AboutCompanyProfile__wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 86px;
  margin-right: -90px;

  @media (--md) {
    display: block;
    margin-top: 15px;
    margin-right: 0;
  }
}

.AboutCompanyProfile__data {
  flex-basis: 600px;
  margin-left: 90px;
  font-size: 0.9285rem;
  border-bottom: solid 1px #cecece;

  @media (--md) {
    margin-left: 0;
  }
}

.AboutCompanyProfile__dataItem {
  padding-top: 20px;
  padding-bottom: 20px;

  + .AboutCompanyProfile__dataItem {
    border-top: solid 1px #cecece;
  }

  b,
  span {
    display: block;
  }

  span + span {
    margin-top: 10px;
  }

  a {
    color: currentColor;
  }

  dt {
    float: left;
    width: 10em;
    font-weight: bold;
  }

  dd {
    overflow: hidden;
  }

  @media (--md) {
    padding-top: 10px;
    padding-bottom: 10px;

    dt {
      width: 9em;
    }
  }
}

.AboutCompanyProfile__image {
  flex-basis: 500px;

  @media (--md) {
    margin-top: 20px;
    text-align: center;
  }
}

.AboutCompanyProfile__cameraman {
  max-width: 500px;
  margin-top: 10px;
  margin-right: -90px;
  margin-left: auto;
  font-size: 0.9285rem;

  a {
    color: currentColor;

    &:hover {
      text-decoration: none;
    }
  }

  @media (--md) {
    max-width: initial;
    margin-right: 0;
    text-align: center;
  }
}

.AboutCompanyProfile__map {
  height: 500px;
  margin-top: 50px;

  @media (--md) {
    height: 640px;
    margin-top: 25px;
  }
}
