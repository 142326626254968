.Button {
  display: inline-block;
  padding: 12px 40px;
  line-height: 1;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  transition: background-color 0.5s ease-out, color 0.5s ease-out;
  appearance: none;

  @media (--md) {
    font-size: 0.9285rem;
  }
}

.Button__link {
  display: block;
  margin: -12px -40px;
  padding: 12px 40px;
  white-space: nowrap;
  text-decoration: none;
}

.Button.-l {
  padding: 17px 73px;

  .Button__link {
    margin: -17px -73px;
    padding: 17px 73px;
  }

  @media (--md) {
    padding: 12px 40px;

    .Button__link {
      margin: -12px -40px;
      padding: 12px 40px;
    }
  }
}

.Button.-black {
  color: #fff;
  background-color: #000;
  border-color: #000;

  > .Button__link {
    color: #fff;
  }

  &.-current,
  &:hover {
    color: #000;
    background-color: #fff;

    > .Button__link {
      color: #000;
    }
  }
}

.Button.-whiteOutline {
  border-color: #fff;

  > .Button__link {
    color: #fff;
  }

  &:hover {
    background-color: #fff;

    > .Button__link {
      color: var(--colorFontBase);
    }
  }
}

.Button.-white {
  background-color: #fff;
  border-color: #000;

  > .Button__link {
    color: var(--colorFontBase);
  }

  &:hover {
    background-color: #000;
    border-color: #fff;

    > .Button__link {
      color: var(--colorFontBase);
      color: #fff;
    }
  }
}
