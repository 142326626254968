.ServicesPoints {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f9f9f9;

  @media (--md) {
    padding: 25px;
  }
}

.ServicesPoints__wrap {
  margin-top: 70px;

  @media (--md) {
    margin-top: 25px;
  }
}

.ServicesPoints__item {
  display: flex;
  justify-content: space-between;
  background-color: #fff;

  + .ServicesPoints__item {
    margin-top: 40px;
  }

  &:nth-child(even) {
    .ServicesPoints__itemImage {
      order: 2;
    }

    .ServicesPoints__itemBody {
      order: 1;
    }
  }

  @media (--md) {
    display: block;
    max-width: 685px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 25px;

    + .ServicesPoints__item {
      margin-top: 0;
    }
  }
}

.ServicesPoints__itemImage {
  flex-basis: 680px;

  @media (--sm) {
    margin-right: -25px;
    margin-left: -25px;
  }
}

.ServicesPoints__itemBody {
  flex-basis: 680px;
  box-sizing: border-box;
  padding-right: 120px;
  padding-left: 120px;

  @media (--md) {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.ServicesPoints__itemTitle {
  display: flex;
  align-items: center;
  font-size: 1.7142rem;
  line-height: 1.4;

  @media (--md) {
    font-size: 1.2857rem;
    font-size: 1.1428rem;
  }
}

.ServicesPoints__itemNum {
  margin-right: 20px;
  padding: 20px 25px;
  color: #fff;
  font-size: 2.8571rem;
  background-color: #000;

  &::after {
    content: '';
    display: block;
    border-bottom: solid 3px #fff;
  }

  @media (--md) {
    padding: 12px 17px;
    font-size: 1.4285rem;
  }
}

.ServicesPoints__itemSubTitle {
  margin-top: 20px;
  font-size: 1.2857rem;

  @media (--md) {
    margin-top: 15px;
    font-size: 1.0714rem;
  }
}

.ServicesPoints__itemText {
  margin-top: 20px;
  font-size: 0.9285rem;
	text-align: justify;

  @media (--md) {
    margin-top: 15px;
  }
}
