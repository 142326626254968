.AboutProfile {
  margin-top: 40px;
  padding-top: 55px;
  padding-bottom: 50px;
  background-color: #f7f7f7;

  @media (--md) {
    padding: 25px;
  }
}

.AboutProfile__title {
  text-align: center;
}

.AboutProfile__wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (--md) {
    display: block;
    margin-top: 20px;
  }
}

.AboutProfile__data {
  flex-basis: 650px;
  font-size: 0.9285rem;

  dl {
    + dl {
      margin-top: 5px;
    }
  }

  dt {
    float: left;
    width: 6em;
  }

  dd {
    overflow: hidden;
    line-height: 2;
  }

  a {
    color: currentColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (--md) {
    dl {
      + dl {
        margin-top: 10px;
      }
    }
    dd {
      line-height: 1.8;
    }
  }
}

.AboutProfile__message {
  flex-basis: 325px;

  @media (--md) {
    margin-top: 25px;
  }
}

.AboutProfile__messageTitle {
  font-size: 0.9375rem;
}

.AboutProfile__messageText {
  margin-top: 5px;
  font-size: 0.9285rem;
  line-height: 2;

  @media (--md) {
    line-height: 1.8;
  }
}

.AboutProfile__messageImage {
  margin-top: 20px;
  margin-left: 30px;

  @media (--md) {
    margin-left: 0;
    text-align: center;

    img {
      width: 320px;
    }
  }
}
