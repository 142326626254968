.PrivacyPolicy {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #f5f5f5;

  @media (--md) {
    padding: 25px;
  }
  
  dl{
  	margin-top: 60px;
	  font-size: 0.9285rem;
	  line-height: 2;

	  dt{
		  font-weight: bold;
	  }
	  dd{
		  margin-bottom: 2rem;
		  ul{
		  	padding-left: 1.5rem;
			  li{
			  	list-style: disc;
			  }
		  }
	  }
  }
}

.PrivacyPolicy__title {
  text-align: center;
}

.PrivacyPolicy {
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: #fff;

  @media (--md) {
    padding: 25px;
  }
}

.PrivacyPolicy__text {
  margin-top: 35px;
  font-size: 0.9285rem;
  line-height: 2;
  text-align: left;

  @media (--md) {
    margin-top: 20px;
    line-height: 1.8;
    text-align: left;

    br {
      display: none;
    }
  }
}

