.GlobalNav {
  @media (--md) {
    display: none;
  }
}

.GlobalNav__item {
  position: relative;
  display: inline-block;

  + .GlobalNav__item {
    margin-left: 70px;
  }
}

.GlobalNav__itemLink {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  color: inherit;
  text-decoration: none;

  &:hover {
    border-bottom: solid 3px currentColor;
  }

  svg {
    display: inline-block;
    vertical-align: text-top;
  }

  + .GlobalNav__itemLink {
    margin-left: 18px;
  }
}

/* カレント処理 */
.home .GlobalNav__itemLink[href$='design'],
.page-template-page-about .GlobalNav__itemLink[href$='about'],
.category .GlobalNav__itemLink[href$='news'],
.single-post .GlobalNav__itemLink[href$='news'],
.page-template-page-news .GlobalNav__itemLink[href$='news'],
.page-template-page-services .GlobalNav__itemLink[href$='services'],
.page-template-page-contact .GlobalNav__itemLink[href$='contact'],
.single-works .GlobalNav__itemLink[href$='works'],
.tax-works_category .GlobalNav__itemLink[href$='works'],
.post-type-archive-works .GlobalNav__itemLink[href$='works'] {
  border-bottom: solid 3px currentColor;
}
