.GlobalLogo {
  @media (--md) {
    svg {
      width: 130px;
      height: auto;
    }
    path {
      fill: #000;
    }
  }
}
