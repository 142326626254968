.AboutLead {
  display: flex;
  border-top: solid 1px #f2f1f0;

  @media (--md) {
    flex-wrap: wrap;
    border-top: none;
  }
}

.AboutLead__images1,
.AboutLead__images2 {
  flex-basis: 330px;

  @media (--md) {
    flex-basis: 50%;
  }
}

.AboutLead__images1 {
  order: 1;
  @media (--md) {
    text-align: right;
  }
}

.AboutLead__images2 {
  order: 3;

  @media (--md) {
    order: 2;
  }
}

.AboutLead__body {
  flex-basis: 710px;
  order: 2;
  padding-top: 50px;
  padding-bottom: 30px;

  @media (--md) {
    flex-basis: 100%;
    padding-top: 25px;
    padding-bottom: 0;
  }
}

.AboutLead__title {
  font-size: 3.5714rem;
  letter-spacing: 3px;
  text-align: center;

  @media (--md) {
    font-size: 1.2854rem;
  }
}
