:root {
  --fontSizeBase: 14px;
  --colorFontBase: #231815;
  --widthContainerSS: 500px;
  --widthContainerS: 1015px;
  --widthContainerM: 1190px;
  --widthContainerL: 1370px;
  --zindexHide: -1;
  --zindexFoward: 2;
  --zindexModal: 3;
  --fontFamilyGothic: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    'Yu Gothic', YuGothic, Verdana, Meiryo, sans-serif;
}
