.WorksList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin-top: -50px;
  margin-right: auto;
  margin-left: auto;

  @media (--md) {
    display: block;
    margin-top: 0;
  }
}

.WorksList__item {
  flex-basis: 33%;
  margin-top: 50px;

  @media (--md) {
    margin-top: 0;

    + .WorksList__item {
      margin-top: 25px;
    }

    img {
      width: 100%;
    }
  }
}

.WorksList__itemLink {
  color: currentColor;
  text-decoration: none;
}

.WorksList__itemName {
  margin-top: 20px;
  font-size: 0.92rem;
  line-height: 1.42;

  @media (--md) {
    margin-top: 10px;
  }
}

.WorksList__itemTags {
  font-size: 0.92rem;
  line-height: 1.42;
}
