.HomeWorks {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #f2f2f2;

  @media (--md) {
    padding: 25px;
  }
}

.HomeWorks__title {
  font-size: 1.64rem;
  letter-spacing: 1px;
  text-align: center;

  @media (--md) {
    font-size: 1.1428rem;
  }
}

.HomeWorks__list {
  margin-top: 50px;

  @media (--md) {
    margin-top: 25px;
  }
}
